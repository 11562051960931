<template>
    <div class="d-flex px-3 small-text">
        <span>Duration:</span>
		<span class="ms-auto">
            <span>{{ getDateFormat.start }}</span>
            <span class="text-secondary"> to </span>
            <span>{{ getDateFormat.end }}</span>
        </span>
	</div>
</template>

<script>
//eslint-disable-next-line
import { format, parseISO, startOfYear } from 'date-fns'

export default {
    data:() => ({}),
    props:{
        item:{},
        date:{},
    },
    computed:{
        getDateFormat(){
            const start = format(parseISO(this.item.start_date), 'dd MMM');
            const end = format(parseISO(this.item.end_date), 'dd MMM');
            return {
                start: start,
                end: end,
            }
        },
        getStartofMilestoneYear(){
            return format(startOfYear(parseISO(this.item.start_date)), 'yyyy');
        }
    },
    methods:{},
    mounted(){},
}
</script>

<style>

</style>